import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from '../../../../services/auth.service';  // Update the path accordingly
import { MemberService } from '../../../../services/member-service'; // Update the path accordingly
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls:["./login.component.css"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  EmailAddress: string;
  password: string;
  confirmPassword: string;
  loading:boolean = false;
  successful=false;
  showModal = false;
  message: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private memberService: MemberService,
    private cookieService: CookieService,
    private authService: AuthService // Inject AuthService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }
  toggleModal(_message?:string)
  {
    this.loading=false;
    if(_message!=null)
    {
      this.message = _message;
    }

 
    this.showModal = !this.showModal;
  }
  initializeForm() {
    this.loginForm = this.formBuilder.group({
     
      EmailAddress: ['', Validators.required],
      password: ['', Validators.required],
    });
  }


  onloginSubmit() {
     
 
 
    this.loading=true;
     // Assign values from personalDetailsForm to corresponding properties
     const userData = {

      ...this.loginForm.value 
      
    };
   
     this.memberService.login(userData).subscribe({
       next: value =>  this.successfulSignIn(value),
       error: err => this.toggleModal("Sign in failed please check your password and try again" + err.message),
       complete: () => console.log('Observable emitted the complete notification')
     });

     
     
   }


   successfulSignIn(response)
   {
    
    sessionStorage.setItem('authToken', response.token);
    sessionStorage.setItem('memberId',response.memBerId);
    this.cookieService.set('authToken', response.token);
     this.toggleModal("Sign up Succesfull you adventure awaits");
     this.successful=true;
     this.router.navigate(['/profile']);
   }
 
  login() {
    if (this.loginForm.valid) {
      const username = this.loginForm.get('username').value;
      const password = this.loginForm.get('password').value;

      this.authService.login(username, password).subscribe(
        (isAuthenticated) => {
          if (isAuthenticated) {
            console.log('Login successful');
            // Other logic after successful login...

            this.router.navigate(['/profile']);
          } else {
            console.error('Login failed');
            // Handle login failure, display error message, etc.
          }
        },
        (error) => {
          console.error('Login error:', error);
        }
      );
    } else {
      // Form is invalid, handle validation errors
    }
  }
}
