 
<div class="flex flex-wrap mt-4">
  <div class="w-full xl:w-12 mb-12 xl:mb-0 px-4">
    <!-- your-component.component.html -->

<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Define columns -->
  <ng-container matColumnDef="vacationId">
    <mat-header-cell *matHeaderCellDef>Vacation ID</mat-header-cell>
    <mat-cell *matCellDef="let trip">{{ trip.vacationId }}</mat-cell>
  </ng-container>

  <!-- Example: Trip Name -->
  <ng-container matColumnDef="tripName">
    <mat-header-cell *matHeaderCellDef>Trip Name</mat-header-cell>
    <mat-cell *matCellDef="let trip">{{ trip.tripName }}</mat-cell>
  </ng-container>

  <!-- Define more columns as needed -->
  <!-- For example: Description -->
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
    <mat-cell *matCellDef="let trip">{{ trip.description }}</mat-cell>
  </ng-container>

  <!-- Repeat this pattern for other columns -->

  <!-- Define column for actions (if needed) -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let trip">
      <!-- Add action buttons here -->
      <!-- Example: Edit and Delete buttons -->
      <button mat-icon-button  >
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button >
        <mat-icon>delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <!-- Define table rows -->
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

  <div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">Trips</h6>
     
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form *ngIf="!showPreview" [formGroup]="tripForm">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Trip Information
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-name"
            >
              Trip Name
            </label>
            <input
              type="text"
              id="trip-name"
              formControlName="tripName"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <!-- Image input 1 -->
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="image1">Image 1</label>
            <input type="file" id="image1" accept="image/*" (change)="onFileSelected($event, 0)" />
 

          </div>
        </div>

        <!-- Repeat the above structure for image 2 and image 3 -->
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="image2">Image 2</label>
            <input type="file" id="image2" accept="image/*" (change)="onFileSelected($event, 1)" />
   
   
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="image3">Image 3</label>
            <input type="file" id="image3" accept="image/*" (change)="onFileSelected($event, 2)" />
   
    
          </div>
        </div>

        
        
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-description"
            >
              Description
            </label>
            <textarea
              id="trip-description"
              formControlName="description"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="tripType">
              Trip Type
            </label>
            <select id="tripType" formControlName="tripType" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
              <option value="Staycation">Staycation</option>
              <option value="International Trip">International Trip</option>
              <option value="Local Trip">Local Trip</option>
            </select>
          </div>
        </div>
        
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-location"
            >
              Location
            </label>
            <input
              type="text"
              id="trip-location"
              formControlName="location"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-location-id"
            >
              Location ID
            </label>
            <input
              type="text"
              id="trip-location-id"
              formControlName="locationId"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-available-slots"
            >
              Available Slots
            </label>
            <input
              type="number"
              id="trip-available-slots"
              formControlName="availableSlots"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-date-created"
            >
              Date Created
            </label>
            <input
              type="date"
              id="trip-date-created"
              formControlName="dateCreated"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-date"
            >
              Trip Date
            </label>
            <input
              type="date"
              id="trip-date"
              formControlName="tripDate"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-cost"
            >
              Cost
            </label>
            <input
              type="number"
              id="trip-cost"
              formControlName="cost"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="trip-deposit-required"
            >
              Deposit Required
            </label>
            <input
              type="number"
              id="trip-deposit-required"
              formControlName="depositRequired"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </div>
        </div>
        <!-- Add the rest of the form fields here with appropriate formControlName bindings -->
      </div>
    
      <hr class="mt-6 border-b-1 border-blueGray-300" />
    
      <button
        (click)="onPreview()"
        class="bg-red-600 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
      >
        Preview
      </button>
    </form>
    
    
    
  </div>
</div>

  </div> 
</div>
<!-- Preview view -->

<div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" *ngIf="showPreview">
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="d-block mx-auto image-carousel" [src]="currentImageSrc" alt="Current Slide">
      </div>
      
      <div class="d-flex justify-content-center">
        <span class="mr-2">
          <i class="fas fa-circle" [class.shaded]="currentImageIndex >= 0"></i> <!-- First dot -->
          <i class="fas fa-circle" [class.shaded]="currentImageIndex >= 1"></i> <!-- Second dot -->
          <i class="fas fa-circle" [class.shaded]="currentImageIndex >= 2"></i> <!-- Third dot -->
        </span>
    
      </div>
      <div class="d-flex justify-content-center">

        <div class="row">
          <button
          (click)="onPreviousImage()"
          class="bg-pink-500 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
        <span class="mr-2">
          <i class="fas fa-chevron-left"></i> <!-- Left arrow -->
        </span>
        </button>
        <button
        (click)="onNextImage()"
        class="bg-pink-500 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
      >
      <span class="mr-2">
        <i class="fas fa-chevron-right"></i> <!-- Right arrow -->
      </span>
  
      </button>
          </div>

      </div>
      
      <div class="mt-2 flex justify-center">
        <div *ngFor="let _ of [0, 1, 2]; let j = index" class="w-2 h-2 mx-1 rounded-full" [class.bg-blue-600]="j === currentImageIndex" [class.bg-gray-400]="j !== currentImageIndex"></div>
      </div>
    </div>

 
  </div>

  <div class="p-5">
    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{tripName}}</h5>
    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{{ description }}</p>
    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Location: {{ location }}</p>
    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Available spots: {{ availableSlots }}</p>
    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Date: {{ tripDate }}</p>
    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Cost: {{ cost }}</p>
    
    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Deposit: {{ depositRequired }}</p>
    <button
    (click)="onEditForm()"
    class="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
    type="button"
  >
    back
  </button>
  <button
  (click)="onSubmit()"
  class="bg-pink-500 text-white active:bg-red-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  type="button"
>
  Publish trip
</button>
  </div>
</div>

 

