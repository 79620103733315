<app-index-navbar></app-index-navbar>
<app-loader *ngIf="loading"></app-loader>

<div class="relative flex w-full flex-wrap items-stretch search-area" *ngIf="searchAreaToggle">
  <i class="fas fa-search search-icon"></i>
  <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"></span>
  <input
    type="text"
    placeholder="Search"
    class="search-bar border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
    (keyup)="filterPosts($event.target.value)"
  />
  <div class="filters">
    <!-- Price Range Slider -->
    <div class="container">
      <div class="row">
        <label for="percentage">Max price: R {{ this.percentage }}</label>
        <input type="range" min="0" max="100000" [(ngModel)]="percentage" (change)="getFilteredPosts()" class="custom-slider" style="margin-left:15%;">
      </div>
      <div class="row">
        <!-- Trip Type Checkboxes -->
        <label for="showStaycations" class="filter-labels">Staycation</label>
        <input type="checkbox" id="staycation" [(ngModel)]="showStaycations" (change)="getFilteredPosts()">

        <label for="showIntenationalTrips" class="filter-labels">International Trip</label>
        <input type="checkbox" id="international" [(ngModel)]="showIntenationalTrips" (change)="getFilteredPosts()">

        <label for="showLocalTrips" class="filter-labels">Local Trip</label>
        <input type="checkbox" id="local" [(ngModel)]="showLocalTrips" (change)="getFilteredPosts()">
      </div>
    </div>
  </div>
</div>

<i class="fas fa-arrow-up open-Search-Button" (click)="toggleSearchMenu()" *ngIf="searchAreaToggle">Close search</i>
<button (click)="toggleSearchMenu()" class="open-Search-Button">
  <i class="fas fa-arrow-down open-Search-Button" *ngIf="!searchAreaToggle">open search</i>
</button>

<div class="grid-container container" [ngStyle]="searchAreaToggle ? {'position': 'absolute', 'top': '60%' ,'left':'10%'} : {'position': 'absolute', 'top': '18%' ,'left':'10%'}">
  <div *ngFor="let trip of trips" class="grid-item max-w-sm   rounded-lg  ">
    <div class="package-block col-lg-4 col-md-6 col-sm-12">
      <div class="inner-box">
        <div class="image-box">
          <div class="image">
            <a href="cape-town.html">
              <img [src]="baseUrl + trip.images[trip.currentImageIndex].imageData" alt="" style="width: 100%; height: 100%;">
            </a>
          </div>
        </div>
        <button class="theme-btn btn-style-one" style="margin: 3%;" (click)="prevImage(trip)">Previous</button>
        <button class="theme-btn btn-style-one" style="margin: 3%;" (click)="nextImage(trip)">Next</button>
        <div class="lower-box">
          <div class="p-icon">
            <span>
              <img src="images/resource/t-icon-1.png" alt="">
            </span>
          </div>
          <div class="location">Location: {{ trip.vaction.location }}</div>
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            <i class="fa fa-suitcase"></i> {{ trip.vaction.title }}
          </h5>
          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{{ trip.vaction.description }}</p>
          <div class="info clearfix">
            <div class="duration">
              <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                <i class="fa fa-female"></i> Available spots: {{ trip.vaction.availableSpots }}
              </p>
            </div>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              <i class="fa fa-clock"></i> Date: {{ trip.vaction.date }}
            </p>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 price">
              <i class="fa fa-credit-card-alt"></i> Cost: R {{ trip.vaction.cost }}
            </p>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              <i class="fa fa-credit-card-alt"></i> Deposit: {{ trip.vaction.deposit }}
            </p>
          </div>
        </div>
        <div class="bottom-box clearfix">
          <!-- Content for bottom box goes here -->
        </div>
        <div class="link-box">
          <button class="theme-btn btn-style-one" (click)="onSubmit(trip)">Reserve A Spot</button>
        </div>
        <div class="link-box" *ngIf="userLoggedIn" style="margin-top: 5%;">
          <button class="theme-btn btn-style-one" (click)="payDeposit(trip)">Pay Deposit</button>
        </div>
        
        <br/>
      </div>
    </div>
  </div>
</div>
