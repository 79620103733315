<app-index-navbar></app-index-navbar>
<section class="header relative pt-16 items-center flex h-screen max-h-860-px">
  <div class="container mx-auto items-center flex flex-wrap">
    <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
      <div class="pt-32 sm:pt-0">
        <h2 class="font-semibold text-4xl text-blueGray-600">
          NoliTravels
        </h2>
        <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
          Your favourite travel app
        </p>
        <div class="mt-12">
          <a
            href="/auth/register"
            target="_blank"
            class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-600 active:bg-red-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Get started
          </a>
          <a
            href="/auth/login"
            class="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            target="_blank"
          >
            Sign In
          </a>
        </div>
      </div>
    </div>
  </div>
  <img
  class="absolute top-0 right-0 pt-16 sm:w-5/12 md:w-6/12 lg:w-5/12 max-w-2xl -mt-48 sm:mt-0 max-h-860-px"
  src="assets/img/NoliTravels.gif"
  alt="..."
/>

</section>


<section class="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
  <div
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0)"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-100 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>
  <div class="container mx-auto">
    <div class="flex flex-wrap items-center">
      <div
        class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32"
      >
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-600"
        >
          <img
            alt="..."
            src="assets/img/plane.png"
            class="w-full align-middle rounded-t-lg"
          />
          <blockquote class="relative p-8 mb-4">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 583 95"
              class="absolute left-0 w-full block h-95-px -top-94-px"
            >
              <polygon
                points="-30,95 583,95 583,65"
                class="text-pink-600 fill-current"
              ></polygon>
            </svg>
            <h4 class="text-xl font-bold text-white">
              Embark on Unforgettable Journeys with NoliTravels! 🌍✈️
            </h4>
            <p class="text-md font-light mt-2 text-white">
           

              Discover the world's hidden gems and create lasting memories with our curated selection of the best trips. Whether you're a thrill-seeker, culture enthusiast, or simply seeking relaxation, we've got the perfect adventure waiting for you.
              
              Why Choose NoliTravels?
              🌟 Handpicked Destinations: Explore carefully selected destinations for the ultimate travel experience.
              🌐 Seamless Planning: Our user-friendly platform makes trip planning a breeze, ensuring a stress-free adventure.
              🌴 Unbeatable Deals: Enjoy exclusive discounts and offers, making your dream vacation more affordable than ever.
            </p>
          </blockquote>
        </div>
      </div>
      <div class="w-full md:w-6/12 px-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col mt-4">
              <div class="px-4 py-5 flex-auto">
                <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                  <i class="fas fa-globe"></i>
                </div>
                <h6 class="text-xl mb-1 font-semibold">Explore Destinations</h6>
                <p class="mb-4 text-blueGray-500">
                  Dive into a world of possibilities with our extensive list of curated travel destinations.
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                  <i class="fas fa-compass"></i>
                </div>
                <h6 class="text-xl mb-1 font-semibold">Guided Adventures</h6>
                <p class="mb-4 text-blueGray-500">
                  Embark on unforgettable journeys with our expertly guided adventures 
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col min-w-0 mt-4">
              <div class="px-4 py-5 flex-auto">
                <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                  <i class="fas fa-camera"></i>
                </div>
                <h6 class="text-xl mb-1 font-semibold">Capture Moments</h6>
                <p class="mb-4 text-blueGray-500">
                  Immerse yourself in travel experiences and capture beautiful moments that will last a lifetime.
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                  <i class="fas fa-book-open"></i>
                </div>
                <h6 class="text-xl mb-1 font-semibold">Discover Stories</h6>
                <p class="mb-4 text-blueGray-500">
                  Our documentation is crafted to make your travel planning and exploration seamless and enjoyable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>

  <div class="container mx-auto overflow-hidden pb-20">
    <div class="flex flex-wrap items-center">
  
      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
        <div class="text-fe65c3 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
          <i class="fas fa-globe text-xl"></i>
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal text-fe65c3">Explore Destinations</h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
          Dive into a world of possibilities with our extensive list of curated travel destinations. Find the perfect spot for your next adventure!
        </p>
        <div class="block pb-6">
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Beaches
          </span>
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Mountains
          </span>
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Cities
          </span>
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Cultural
          </span>
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Adventure
          </span>
        </div>
        <a href="#" class="font-bold text-fe65c3 hover:text-blueGray-500 ease-linear transition-all duration-150">
          Discover More
          <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
        </a>
      </div>
  
      <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
        <div class="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
          <!-- Replace image sources with your actual images -->
          <img alt="Destination 1" src="assets/img/destination-1.jpg" class="w-full align-middle rounded absolute shadow-lg max-w-100-px left-145-px -top-29-px z-3" />
          <img alt="Destination 2" src="assets/img/destination-2.jpg" class="w-full align-middle rounded-lg absolute shadow-lg max-w-210-px left-260-px -top-160-px" />
          <img alt="Destination 3" src="assets/img/destination-3.jpg" class="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px left-40-px -top-225-px z-2" />
          <img alt="Destination 4" src="assets/img/destination-4.jpg" class="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px" />
          <img alt="Destination 5" src="assets/img/destination-5.jpg" class="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px" />
        </div>
      </div>
  
    </div>
  
    <div class="flex flex-wrap items-center pt-32">
  
      <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
        <div class="justify-center flex flex-wrap relative">
          <div class="my-4 w-full lg:w-6/12 px-4">
            <a href="#" target="_blank">
              <div class="bg-fe65c3 shadow-lg rounded-lg text-center p-8">
                <img alt="Svelte Logo" class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/svelte.jpg" />
                <p class="text-lg text-white mt-4 font-semibold">Svelte</p>
              </div>
            </a>
            <!-- Add similar blocks for other technologies -->
          </div>
        </div>
      </div>
  
      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
        <div class="text-fe65c3 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
          <i class="fas fa-plane text-xl"></i>
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal text-fe65c3">Plan Your Journey</h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
          Make the most of your travel experience by planning your journey with our dynamic components. From booking flights to discovering local gems, we've got you covered!
        </p>
        <div class="block pb-6">
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Flight Booking
          </span>
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Itineraries
          </span>
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Travel Tips
          </span>
          <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fe65c3 bg-white uppercase last:mr-0 mr-2 mt-2">
            Accommodations
          </span>
        </div>
        <a href="#" class="font-bold text-fe65c3 hover:text-blueGray-500 ease-linear transition-all duration-150">
          Start Planning Now
          <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
        </a>
      </div>
  
    </div>
  </div>
  
  
</section>

 
 

<section class="pb-16 bg-blueGray-200 relative pt-32">
  <div
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0)"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-200 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>

  <div class="container mx-auto">
    <div
      class="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10"
    >
      <div class="w-full text-center lg:w-8/12">
        <p class="text-4xl text-center">
          <span role="img" aria-label="love"> 😍 </span>
        </p>
        <h3 class="font-semibold text-3xl">NoliTravels</h3>
        <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
        your adventure awaits
        </p>
        <div class="sm:block flex flex-col mt-10">
          <a
            href="/register"
            target="_blank"
            class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-red-600 active:bg-red-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Get started
          </a>
          
        </div>
        <div class="text-center mt-16"></div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
