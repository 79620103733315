<div class="relative flex flex-col min-w-0 break-words  bg-black text-white w-full mb-6 shadow-lg rounded">
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <h3 class="font-semibold text-base text-blueGray-700">{{ title }}</h3>
      </div>
      <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
        <button
          class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button" (click)="exportDataToExcel()"
        >
          Export to excel
        </button>
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr style="border: px; border-color: white; border-top: 2px; border-left: 2px; border-right: 2px; border-bottom: 2px; border-style: solid;">
          <th *ngFor="let column of columns" class="...">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows " style="border-color: white; border-top: 2px; border-left: 2px; border-right: 2px; border-bottom: 2px; border-style: solid;">
          <td *ngFor="let cell of getRowValues(row)" class="...">{{ cell }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>