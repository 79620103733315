import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Payment } from '../models/Payments';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = 'https://localhost:44316/api/payments';

  constructor(private http: HttpClient) { }

  getPayments() {
    return this.http.get<Payment[]>(this.apiUrl);
  }

  getPayment(id: number) {
    return this.http.get<Payment>(`${this.apiUrl}/${id}`);
  }

  createPayment(paymentRequest: any) {
    return this.http.post<any>(this.apiUrl, paymentRequest);
  }

  notify() {
    return this.http.post<any>(`${this.apiUrl}/notify`, {});
  }

  success() {
    return this.http.get<any>(`${this.apiUrl}/success`);
  }

  cancel() {
    return this.http.get<any>(`${this.apiUrl}/cancel`);
  }
}
