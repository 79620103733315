import { Component, OnInit } from "@angular/core";
import { Member } from "src/models/Member";
import { MemberService } from "src/services/member-service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
})
export class ProfileComponent implements OnInit {
  constructor(
    private memberService: MemberService,
  ) {}


  imageUrl: string = 'assets/img/team-2-800x800.jpg';
  
 
  profile :Member ;

  ngOnInit(): void 
  { 
    this.memberService.getMember(null).subscribe({
      next: value =>  this.initializeProfile(value),
      error: err => console.log("Sign in failed please check your password and try again" + err.message),
      complete: () => console.log('Observable emitted the complete notification')
    });
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    
    if (file) {
      // Perform any necessary actions, such as uploading the file to the server
      // For now, just update the imageUrl with a local URL
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  initializeProfile(member)
  {

    this.profile =member;
    console.log(this.profile);
  }
}
