import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private cookieService: CookieService, private router:Router) {}

  login(username: string, password: string): Observable<boolean> {
    // Here, you can implement your actual authentication logic.
    // For simplicity, let's assume any non-empty username/password is valid.

    if (username && password) {
      // Set a cookie to simulate authentication
      this.cookieService.set('authToken', 'yourAuthTokenValue');

      return of(true); // Simulate a successful login
    } else {
      return of(false); // Simulate a failed login
    }
  }

  logout(): void {
    // Remove the authentication cookie
    this.cookieService.delete('authToken');
    sessionStorage.removeItem('authToken');
    this.router.navigate(["/vacations"]);
 
  }


  isAuthenticated(): boolean {
    // Check if the authentication cookie is present4
    var x =  sessionStorage.getItem('authToken');
    if(x!=null){
      console.log(" authenticated")
   
      return true;
    }else{
      console.log(" not authenticated")
      return false;
    }

  }
}
