import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MemberService } from '../../../../services/member-service'; // Update the path accordingly

  @Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls:["./register.component.css"]
  })
export class RegisterComponent implements OnInit {
  registrationForm: FormGroup;
  personalDetailsForm: FormGroup;

  currentStep: 'credentials' | 'personalDetails' = 'credentials';

  // Declare properties for form fields
  // Declare properties for form fields
  email: string;
  password: string;
  confirmPassword: string;
  loading:boolean = false;
  name: string;
  surname: string;
  phoneNumber: string;
  birthDate: Date;
  description: string;
  location: string;
  successful=false;

  showModal = false;
  message: string;
  toggleModal(_message?:string)
  {
    this.loading=false;
    if(_message!=null)
    {
      this.message = _message;
    }

 
    this.showModal = !this.showModal;
  }
  // Array of city names
  cities: { id: number, city: string }[] = [
    { id: 1, city: 'Johannesburg' },
    { id: 2, city: 'Cape Town' },
    { id: 3, city: 'Durban' },
    { id: 4, city: 'Pretoria' },
    { id: 5, city: 'Port Elizabeth' },
    { id: 6, city: 'Bloemfontein' },
    { id: 7, city: 'Polokwane' },
    { id: 8, city: 'Nelspruit' },
    { id: 9, city: 'Kimberley' },
    { id: 10, city: 'East London' },
    { id: 11, city: 'Mthatha' },
    { id: 12, city: 'Lusaka' },
    { id: 13, city: 'Ndola' },
    { id: 14, city: 'Kitwe' },
    { id: 15, city: 'Harare' },
    { id: 16, city: 'Bulawayo' },
    { id: 17, city: 'Mutare' },
  ];
  
  constructor(
    private formBuilder: FormBuilder,
    private memberService: MemberService
  ) {}

  ngOnInit(): void {
    this.initializeForms();
  }

  initializeForms() {
    this.registrationForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.personalDetailsForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      birthDate: ['', Validators.required],
      description: ['', Validators.required],
      location: ['', Validators.required],
    });
  }

  checkPassword() {
    const passwordControl = this.registrationForm.get('password');
    const confirmPasswordControl = this.registrationForm.get('confirmPassword');

    if (passwordControl && confirmPasswordControl) {
      const password = passwordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      confirmPasswordControl.setErrors(this.passwordMatchValidator(password, confirmPassword));
    }
  }

  passwordMatchValidator(password: string, confirmPassword: string) {
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  successfulSignup()
  {
    
    this.toggleModal("Sign up Succesfull you adventure awaits");
    this.successful=true;
  }

  


  nextStep() {
    this.currentStep = 'personalDetails';
    console.log("clicked")
  }

  backStep() {
    this.currentStep = 'credentials';
  }

   
  onCredentialsSubmit() {
    // Add your logic for credentials form submission if needed
  }

  onPersonalDetailsSubmit() {
     

     this.loading=true;
      // Assign values from personalDetailsForm to corresponding properties
      this.name = this.personalDetailsForm.get('name').value;
      this.surname = this.personalDetailsForm.get('surname').value;
      this.phoneNumber = this.personalDetailsForm.get('phoneNumber').value;
      this.birthDate = this.personalDetailsForm.get('birthDate').value;
      this.description = this.personalDetailsForm.get('description').value;
      this.email =this.registrationForm.get('email').value;
      console.log("test",this.personalDetailsForm.get('birthDate').value);


      const userData = {

        ...this.registrationForm.value,
        ...this.personalDetailsForm.value,
        
      };
    
      userData.LocationId=this.location;
      
      userData.EmailAddress =   this.email,
      console.log(userData);
      this.memberService.postMember(userData).subscribe({
        next: value => this.successfulSignup(),
        error: err => this.toggleModal("Sign up failed please try again" + err.message),
        complete: () => console.log('Observable emitted the complete notification')
      });

      
      
    }
  }
     


