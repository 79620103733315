<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-6/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
      >

      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
     <!-- ... (Previous HTML code) ... -->

     <form id="credentialsForm" *ngIf="currentStep === 'credentials'" (ngSubmit)="onCredentialsSubmit()" [formGroup]="registrationForm">
      <div class="text-blueGray-400 text-center mb-3 font-bold">
        <small>Step 1: Credentials</small>
      </div>
    
      <div class="relative w-full mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="email">
          Email
        </label>
        <input
          type="email"
          formControlName="email"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Email"
          required
        />
        <!-- Display email validation error -->
        <div *ngIf="registrationForm.get('email').hasError('email') && registrationForm.get('email').touched" class="text-red-600 mt-1">
          Invalid email address
        </div>
      </div>
    
      <div class="relative w-full mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="password">
          Password
        </label>
        <input
          type="password"
          formControlName="password"
          (input)="checkPassword()"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Password"
          required
        />
        <!-- Display password validation error -->
        <div *ngIf="registrationForm.get('password').hasError('minlength') && registrationForm.get('password').touched" class="text-red-600 mt-1">
          Password must be at least 6 characters long
        </div>
      </div>
    
      <div class="relative w-full mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="confirmPassword">
          Confirm Password
        </label>
        <input
          type="password"
          formControlName="confirmPassword"
          (input)="checkPassword()"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Confirm Password"
          required
        />
        <!-- Display password match validation error -->
        <div *ngIf="registrationForm.hasError('passwordMismatch') && registrationForm.get('confirmPassword').touched" class="text-red-600 mt-1">
          Passwords do not match
        </div>
      </div>
    
      <!-- Display form validation error -->
      <!-- <div *ngIf="registrationForm.invalid && registrationForm.touched" class="text-red-600 mt-1">
        Please fix the errors in the form.
      </div> -->
    
      <div class="text-center mt-6">
        <button
        
          class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
          type="button"
          (click)="nextStep()"
        >
          Next
        </button>
      </div>
    </form>
    

<!-- ... (Continue with the rest of the HTML) ... -->

        <div class="flex-auto px-4 lg:px-10 py-10 pt-0" *ngIf="currentStep === 'personalDetails'">
          <div class="text-blueGray-400 text-center mb-3 font-bold">
            <small>Step 2: Personal Details</small>
          </div>
          <form id="personalDetailsForm" *ngIf="currentStep === 'personalDetails'" (ngSubmit)="onPersonalDetailsSubmit()" [formGroup]="personalDetailsForm">
           
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Name
              </label>
              <input
                type="text"
                formControlName="name"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Name"
                required
              />
            </div>
           
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Surname
              </label>
              <input
                type="text"
              
                [(ngModel)]="surname"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Surname"
                required
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Phone Number
              </label>
              <input
                type="tel"
                formControlName="phoneNumber"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Phone Number"
                required
              />
            </div>
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Birth Date
              </label>
              <input
                type="date"
                formControlName="birthDate"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Birth Date"
                required
              />
            </div>
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Location
              </label>
              <!-- Use *ngFor to loop through cities array and populate options in the dropdown -->
              <select
                name="location"
                formControlName="location"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                required
              >
                <option *ngFor="let city of cities" [value]="city.id">{{ city.city }}</option>
              </select>
            </div>
            
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                Reason for Joining
              </label>
              <textarea
                name="description"
                formControlName="description"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Reason for Joining"
                required
              ></textarea>
            </div>
            <!-- Add more fields for personal details -->

            <div class="text-center mt-6">
              <button
              class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              type="button"
              (click)="backStep()"
            >
              Back
            </button>
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
                (click)="onPersonalDetailsSubmit"
              >
                Create Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
 
<div *ngIf="showModal" class="popup overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-6xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
        <h3 class="text-3xl font-semibold">
            Alert:
        </h3>
        <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" (click)="toggleModal()">
          <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
            ×
          </span>
        </button>
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">
        <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
          {{message}}
      </div>
      <!--footer-->
      <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
        <button  *ngIf="!successful" class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
          Close
        </button>
     <a href="auth/login"  *ngIf="successful"  ><button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
          continue
        </button></a>  
      </div>
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>`
<div role="status " class="loader" *ngIf="loading">
  <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
  </svg>
  <span class="sr-only">Loading...</span>
</div>