<nav class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
  <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
    <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
      <a
        [routerLink]="['/']"
        class="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
      >
        NoliTravels
      </a>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        (click)="setNavbarOpen()"
      >
        <i class="fas fa-bars"></i>
      </button>
    </div>
    <div
      class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
      [ngClass]="{ hidden: !navbarOpen, 'block rounded shadow-lg': navbarOpen }"
      id="example-navbar-warning"
    >
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
        <li class="flex items-center">
          <a
            class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.tiktok.com/@nolitravels"
            target="_blank"
          >
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-cocktail text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2">Events</span>
          </a>
        </li>
        <li class="flex items-center">
         
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2" (click)="loadVacations()">International Trips</span>
       
        </li>
        <li class="flex items-center">
          <a
            class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.instagram.com/nolitravels/"
            target="_blank"
          >
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2">Staycations</span>
          </a>
        </li>
        <li class="flex items-center">
          <a
            class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.instagram.com/nolitravels/"
            target="_blank"
          >
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2">Local Trips</span>
          </a>
        </li>
        <li class="flex items-center">
          <a
            class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.instagram.com/nolitravels/"
            target="_blank"
          >
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2">Review Experience</span>
          </a>
        </li>
        <li class="flex items-center" *ngIf="isAuthenticated">
          <a class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="/profile">
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2">Profile</span>
          </a>
        </li>
        <li class="flex items-center" *ngIf="isAuthenticated">
          <a class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" (click)="logout()">
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2" >Logout</span>
          </a>
        </li>
        <li class="flex items-center" *ngIf="!isAuthenticated">
          <a class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="/login">
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2">Login</span>
          </a>
        </li>
        <li class="flex items-center" *ngIf="!isAuthenticated">
          <a class="lg:text-black lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="/signup">
            <i class="lg:text-blueGray-200 text-blueGray-400 fab fa-university text-lg leading-lg"></i>
            <span class="lg:block inline-block ml-2">Sign Up</span>
          </a>
        </li>
       
      </ul>
    </div>
  </div>
</nav>
