import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ExportService } from "src/services/export-service.service";

@Component({
  selector: "app-card-page-visits",
  templateUrl: "./card-page-visits.component.html",
})
export class CardPageVisitsComponent implements OnInit {

  
  // Infer columns from the keys of the first object in the array
  
  @Input() angularObject$: Observable<any>;

  public angularObject :any;
  public angularOBjectArray;
 

  constructor(private exportService: ExportService)
  {

  }


  // Initialize the title, columns, and rows
  title: string = 'Page visits';
  columns: string[] = [];
  rows: any[] = [];




  // Infer columns from the keys of the first object in the array
  private inferColumns(data: any[]): void {
    this.angularObject =data;
    if (data.length > 0) {
      this.columns = Object.keys(data[0]);
    }
  }

    
  exportDataToExcel() {
    this.exportService.exportToExcel(this.angularOBjectArray, 'exported_data', 'Sheet1');
  }
  // Extract rows from the angularObject
  private extractRows(data: any[]): void {
    this.rows = data;
  }

  // Get the values of a row
  getRowValues(row: any): any[] {
    return Object.values(row);
  }


  private initTable(data)
  {

    this.angularOBjectArray = data;
  console.debug(data , "this is a test")
    this.inferColumns(data);
    this.extractRows(data);
  }

  // Lifecycle hook to initialize data
  ngOnInit(): void {

   
    this.angularObject$.subscribe( {
      next: value =>  this.initTable(value),
      error: err => console.log("request failed please  try again" + err.message),
      complete: () => console.log('Observable emitted the complete notification')
  
    });
  }
}