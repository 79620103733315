<a
  class="text-blueGray-500 block py-1 px-3"
  href="#pablo"
  (click)="toggleDropdown($event)"
  #btnDropdownRef
>
  <i class="fas fa-bell"></i>
</a>
<div class="min-w-48 z-50" #popoverDropdownRef>
  <div
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
    [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  >
    <a
      href="#pablo"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Action
    </a>
    <a
      href="#pablo"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Another action
    </a>
    <a
      href="#pablo"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Something else here
    </a>
    <div class="h-0 my-2 border border-solid border-blueGray-100"></div>
    <a
      href="#pablo"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
    >
      Seprated link
    </a>
  </div>
</div>
