import { Component, OnInit } from "@angular/core";
import { Member } from "src/models/Member";
import { MemberService } from "src/services/member-service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  constructor(
    private memberService: MemberService,
  ) {}

  public members:any;
  ngOnInit()
  
  {
    this.members = this.memberService.getAllMembers() 
    
  }
 

}
  