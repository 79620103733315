import { Component, OnInit } from '@angular/core';
import { TripService } from 'src/services/trip-service';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { PaymentService } from 'src/services/payment.service';
import { MemberActivityService } from 'src/services/memberactivity-service';
@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {
  originalTrips: any[];
  
  constructor(private tripService: TripService,private http: HttpClient,private location: Location,
    private authService: AuthService, private router: Router,private paymentService: PaymentService
  ) 
  { 
    if(this.authService.isAuthenticated())
    {
      this.userLoggedIn = true;
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  public trips: any;
  public baseUrl: string ="https://localhost:44316/uploads/";
  public percentage = 50000;
  public showLocalTrips:boolean =true;
  public showIntenationalTrips:boolean =true;
  public showStaycations:boolean =true;
  public showEvents:boolean =true;
  public selectedLocation :string="test";
  public availableLocations :string[] = [];
  public loading: boolean = true;
  public userLoggedIn: boolean = false;
  public searchAreaToggle : boolean =false;
  resetTrips() {
    // Reset trips to the original array
    this.trips = [...this.originalTrips];
  }
  
  getFilteredPosts() {
    // Filter posts based on price range
  this.resetTrips();
    console.log(this.trips);
  this.trips =this.trips.filter(post => 
      post.vaction?.cost <= this.percentage);
  }

  toggleSearchMenu(){
    this.searchAreaToggle = this.searchAreaToggle ? false : true;
  }
  
  getData() {
    this.loading = true;
    this.tripService.getTrip().subscribe(
      trip => {
        this.trips = trip;
        this.originalTrips = trip;
        this.resetTrips();
        this.initializeCurrentImageIndex();
  
        this.trips.forEach(item => {
          this.availableLocations.push(item?.vaction?.location);
        });
        this.availableLocations =  Array.from(new Set(this.availableLocations));
        this.loading = false;
      },
      error => {
        // Handle error
        this.loading = false;
        console.error('Error fetching trip data:', error);
      }
    );
  }

  initializeCurrentImageIndex() {
    this.trips.forEach(trip => {
      trip.currentImageIndex = 0;
    });
  }

  filterPosts(query: string) {
    // If query is empty, reset the filtered posts to all posts
    this.resetTrips();
    if (!query.trim()) {
      this.getData();
      return;
    }

    // Convert query to lowercase for case-insensitive search
    const lowerCaseQuery = query.toLowerCase();
  
    // Filter posts based on search query
    this.trips = this.trips.filter(trip =>
      Object.values(trip.vaction).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(lowerCaseQuery)
      )
    );
  }

  nextImage(trip: any) {
    if (trip.currentImageIndex < trip.images.length - 1) {
      trip.currentImageIndex++;
    }
  }

  prevImage(trip: any) {
    if (trip.currentImageIndex > 0) {
      trip.currentImageIndex--;
    }
  }

  onSubmit(trip: any) {
    // Logic to handle form submission
    if(this.authService.isAuthenticated())
    {
      console.log(trip);



      const payment = {    
        totalAmount: trip.vaction.cost,
        description: trip.vaction.tripName,
        tripId: trip.vaction.vacationId.toString(),
        memberId: sessionStorage.getItem('memberId')
      };
      

      const paymentRequest = {
        Payment: payment,
        Member: {
          
           MemberId : sessionStorage.getItem('memberId')
        }
      };

      console.log(paymentRequest);
      this.paymentService.createPayment(paymentRequest).subscribe()
    }else
    {
 
      this.router.navigate(['/login']);
    }
  }

  getBaseUrl(): string {
    return window.location.origin; // Get the full base URL including protocol, domain, and port if any

  }
}


