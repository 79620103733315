import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";

@Component({
  selector: "app-index-navbar",
  templateUrl: "./index-navbar.component.html",
})
export class IndexNavbarComponent implements OnInit {
  navbarOpen = false;
  isAuthenticated =false;
  constructor(private authService: AuthService,private router: Router) {}

  ngOnInit(): void {

    this.isAuthenticated = this.authService.isAuthenticated();
  }

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  loadVacations() {
    this.router.navigate(["/vacations"]);
  }
 

  logout() {
    this.authService.logout();

    this.loadVacations();
    // Implement logout logic, such as redirecting to the home page
  }
}
