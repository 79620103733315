<nav
  class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
>
  <div
    class="container px-4 mx-auto flex flex-wrap items-center justify-between"
  >
    <div
      class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
    >
      <a
        href="https://noliTravels.co.za"
        class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
      >
       Noli Travels
      </a>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        (click)="setNavbarOpen()"
      >
        <i class="text-white fas fa-bars"></i>
      </button>
    </div>
    <div
      class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
      [ngClass]="{ hidden: !navbarOpen, 'block rounded shadow-lg': navbarOpen }"
      id="example-navbar-warning"
    >
    
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
    
   

        <li class="flex items-center">
          <a
            class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.tiktok.com/@nolitravels"
            target="_blank"
          >
            <i
              class="lg:text-blueGray-200 text-blueGray-400 fab fa-tiktok text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">TikTok</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.instagram.com/nolitravels/"
            target="_blank"
          >
            <i
              class="lg:text-blueGray-200 text-blueGray-400 fab fa-instagram text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">Instagram</span>
          </a>
        </li>

    
      </ul>
    </div>
  </div>
</nav>
