import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CommonBaseApiService } from './common-base-api.service';
import { Trip } from '../models/Trip';

@Injectable({ providedIn: 'root' })
export class TripService extends CommonBaseApiService {
  getTrip(): Observable<Trip[]> {
    return this.get<Trip>(`Trip`);
  }

  postTrip(request: any): Observable<any> {
    return this.post<any>(`Trip`, request);
  }

  getTripById(id: any): Observable<Trip> {
    return this.getById<Trip>(`Trip`, id);
  }

  putTripById(request: Trip): Observable<Trip> {
    return this.putById<Trip>(`Trip`, request);
  }

  deleteTripById(id: any): Observable<void> {
    return this.deleteById(`Trip`, id);
  }
}
