import { Component, OnInit } from "@angular/core";
import { TripService } from "../../../../services/trip-service";
import { MatTableDataSource } from '@angular/material/table';

  
import { FormBuilder, FormGroup, Validators ,FormArray } from "@angular/forms"; // Import necessary Angular forms modules

@Component({
  selector: "app-trips",
  templateUrl: "./trips.component.html",
  styleUrls: ["./trips.component.css"]
})
export class TripsComponent implements OnInit {
  public trips: Array<any> =[];
  public tripForm: FormGroup; // Define a FormGroup for the form
  public showPreview: boolean = false;

  public currentImageIndex: number = 0;

  displayedColumns: string[] = ['vacationId', 'tripName', 'description', 'location', 'availableSlots', 'tripDate', 'cost', 'depositRequired'];

  // Define the data source for the table
  dataSource = new MatTableDataSource<any>();
  imageSrcs: string[] = ['', '', ''];
  tripName: [string, typeof Validators.required];
  images: ReturnType<typeof this.fb.array>;
  description: [string, typeof Validators.required];
  location: [string, typeof Validators.required];
  locationId: [string | null];
  availableSlots: [number, typeof Validators.required];
  dateCreated: [string, typeof Validators.required];
  tripDate: [string, typeof Validators.required];
  cost: [number, typeof Validators.required];
  depositRequired: [number, typeof Validators.required];
  imageBlob: any;
  imageBlobs: any;
  pictures: Array<any>;
  currentImageSrc: string;
  currentImageIndexArray: number;
 
  imageNames: string[] = ['', '', ''];

  constructor(
    private tripService: TripService,
 
    private fb: FormBuilder // Inject the FormBuilder
  ) {}

  initlizeTrips(trips:any)
  {
  
    trips.forEach(vaction => {
  
      this.trips.push(vaction.vaction);
     
    });

    this.dataSource.data = [this.trips];
    console.log("trips>>>>>>>",this.trips)
  }

  ngOnInit() {
    this.tripService.getTrip().subscribe( trips => this.initlizeTrips(trips));
    // Initialize the form with default values or empty strings
    this.tripForm = this.fb.group({
      tripName: ["", Validators.required],
      description: ["", Validators.required],
      location: ["", Validators.required],
      locationId: [null], // Assuming it's optional, adjust accordingly
      availableSlots: [0, Validators.required],
      dateCreated: ["", Validators.required],
      tripDate: ["", Validators.required],
      cost: [0, Validators.required],
      depositRequired: [0, Validators.required],
    });
  }


  onFileSelected(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageSrcs[index] = reader.result as string;
        this.imageNames[index] = file.name;
      };
      reader.readAsDataURL(file);
    }
  }

  getFileDisplayName(url: string) {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }
  onPreview() {
    this.tripName = this.tripForm.value.tripName;
    this.description = this.tripForm.value.description;
    this.location = this.tripForm.value.location;
    this.locationId = this.tripForm.value.locationId;
    this.availableSlots = this.tripForm.value.availableSlots;
    this.dateCreated = this.tripForm.value.dateCreated;
    this.tripDate = this.tripForm.value.tripDate;
    this.cost = this.tripForm.value.cost;
    this.depositRequired = this.tripForm.value.depositRequired;
    this.showPreview = true;
    this.currentImageSrc = this.imageSrcs[0]; 

    this.pictures = this.tripForm.value.images;
    this.currentImageIndex = 0;
}
 

onEditForm() {
  this.showPreview = false;
}
 

onSubmit() {
  const formData = new FormData();
 
  // Append all form data dynamically
  Object.keys(this.tripForm.value).forEach(key => {
    const value = this.tripForm.value[key];
    if (Array.isArray(value)) {
      // If the value is an array (e.g., multiple select), append each item separately
      value.forEach((item, index) => {
        formData.append(`${key}_${index}`, item);
      });
    } else {
      // Append single values directly
      formData.append(key, value);
    }
  });
  // Append images as file attachments
  this.imageSrcs.forEach((imageSrc, index) => {
    const blob = this.dataURItoBlob(imageSrc);
    formData.append('image' + index, blob, 'image_' + index + '.jpg');
  });

  // Send formData to the backend
  this.tripService.postTrip(formData).subscribe({
    next: value => console.log(value),
    error: err => console.log("An error occurred while sending the form data: " + err.message),
    complete: () => console.log('Observable emitted the complete notification')
  });
}

dataURItoBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
}


onPreviousImage() {

  if(this.currentImageIndex>1){
    this.currentImageIndex --;
    this.currentImageSrc =this.imageSrcs[this.currentImageIndex];
  }else
  {
    this.currentImageIndex =this.imageSrcs.length -1;;
    this.currentImageSrc =this.imageSrcs[this.currentImageIndex];
  }
}

onNextImage() {

  if (this.currentImageIndex < this.imageSrcs.length - 1) {
    this.currentImageIndex++;
    this.currentImageSrc = this.imageSrcs[this.currentImageIndex];
  } else {
    this.currentImageIndex = 0;
    this.currentImageSrc = this.imageSrcs[this.currentImageIndex];
  }
}

 
  

  onBack() {
    this.showPreview = false;
  }

  // Function to handle form submission
 
}
