import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonBaseApiService {
  constructor(protected http: HttpClient) {}

  protected baseUrl = 'https://localhost:44316/api';

  protected get<T>(endpoint: string): Observable<T[]> {
    const url = `${this.baseUrl}/${endpoint}`;
    return this.http.get<T[]>(url);
  }

  protected post<T>(endpoint: string, request: T): Observable<T> {
    const url = `${this.baseUrl}/${endpoint}`;
    return this.http.post<T>(url, request);
  }

  protected getById<T>(endpoint: string, id: any): Observable<T> {
    const url = `${this.baseUrl}/${endpoint}/${id}`;
    return this.http.get<T>(url);
  }

  protected putById<T>(endpoint: string, request: T): Observable<T> {
    const url = `${this.baseUrl}/${endpoint}/${request}`;
    return this.http.put<T>(url, request);
  }

  protected deleteById(endpoint: string, id: any): Observable<void> {
    const url = `${this.baseUrl}/${endpoint}/${id}`;
    return this.http.delete<void>(url);
  }
}
