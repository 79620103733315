// auth.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // Check if the user is authenticated (you can modify this condition based on your authentication logic)
    if (sessionStorage.getItem('authToken')) {
      return true; // Allow access
    }

    // If not authenticated, redirect to the login page
    this.router.navigate(['/login']);
    return false;
  }
}
