import { Injectable } from '@angular/core';
 
import { Observable } from 'rxjs';
import { CommonBaseApiService } from './common-base-api.service';
import { Member } from '../models/Member';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MemberService extends CommonBaseApiService {

  protected baseUrl = 'https://localhost:44316/api';
  exportService: any;

  postMember(request: Member): Observable<Member> {
    return this.post<Member>(`Member`, request);
  }
  login(request: Member): Observable<Member> {
    return this.post<Member>(`Member/Login`, request);
  }
  getMember(memBerId: string): Observable<Member[]> {
    var token = sessionStorage.getItem('authToken');
    var member  = sessionStorage.getItem('memberId');
   
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });



    return this.http.get<Member[]>(`${this.baseUrl}/Member/${member}`, { headers });
  
  
  }

  putMemberById(request: Member): Observable<Member> {
    return this.putById<Member>(`Member`, request);
  }


  getAllMembers(): Observable<Member[]> {
    var token = sessionStorage.getItem('authToken');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.get<Member[]>(`${this.baseUrl}/Member`, { headers });
  }
  deleteMemberById(id: any): Observable<void> {
    return this.deleteById(`Member`, id);
  }


}
